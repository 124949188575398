import './searchBar.style.scss'
import searchIconDark from '../../assets/icons/searchIconDark.svg';
import closeIcon from '../../assets/icons/closeIconMedium.svg';
import { useEffect, useRef, useState } from 'react';
import { ParagraphComponent } from '..';
import { useDebounce } from './useDebounce.component';
import Categories, { CategoryEquipment, convertChargerCategoryLink } from '../../pages/equipment/categories';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSearchStore } from '../../store';
import Fuse from 'fuse.js';
import { emptySearchResult, searchProductType, SearchResult } from '../../store/searchStore';
import SearchResultComponent from './searchResult.component';


export interface responseDataType {
    products: searchProductType[],
    searchCategories: string[]
}

interface Props {
    clickSearch?: boolean;
    setClickSearch?: (arg0: boolean) => void;
    clickSearchMobile?: boolean;
    setClickSearchMobile?: (arg0: boolean) => void;
}


function SearchBarComponent(props: Props) {
    const { t, i18n } = useTranslation();
    const { clickSearch, setClickSearch, clickSearchMobile, setClickSearchMobile } = props;
    const [clickSearchProp, setClickSearchProp] = useState(props)
    const navigate = useNavigate()
    // const [loading, setLoading] = useState(false)
    const [categories, setCategories] = useState<{ key: string; name: string }[]>([])
    const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
    const searchBarRef = useRef<HTMLDivElement>(null);
    const seeAllRef = useRef<HTMLDivElement>(null);

    const { searchResults, fetchSearchResults, loading, setLoading, error, resetSearch, keyword, setKeyword, categoriesStore } = useSearchStore();

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (setClickSearch) {
                if (!searchBarRef.current) { setClickSearch(false); return null }

                const target = event.target as HTMLElement;

                // Check if the click is inside searchBarRef
                if (searchBarRef.current.contains(target)) {
                    if (target.classList.contains('seeAll')) {
                        setClickSearch(true);
                    } else {
                        setClickSearch(false);
                    }
                } else {
                    setClickSearch(true);
                }
            } else if(setClickSearchMobile) {
                if (!searchBarRef.current) { setClickSearchMobile(false); return null }

                const target = event.target as HTMLElement;

                // Check if the click is inside searchBarRef
                if (searchBarRef.current.contains(target)) {
                    if (target.classList.contains('seeAll')) {
                        setClickSearchMobile(true);
                    } else {
                        setClickSearchMobile(false);
                    }
                } else {
                    setClickSearchMobile(true);
                }
            }
        }

        // Attach the event listener
        document.addEventListener('click', handleClickOutside);

        // Cleanup the event listener
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);


    useEffect(() => {
        if (loading == false) handleChange(keyword)

    }, [keyword]);

    useEffect(() => {
        setCategories(findCategoryMatches(keyword));

    }, [searchResults]);


    const handleChange = (value: string) => {
        setKeyword(value);


        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        if (value.length >= 3) {
            setLoading(true)
            debounceTimeout.current = setTimeout(() => {
                fetchSearchResults(value, 0)
                // searchResults.data.searchCategories=doesCategoryExist(value)

                setCategories(findCategoryMatches(value));


            }, 500);
        } else {
            // setResults({ products: [], searchCategories: [] });
        }

        if (searchResults.data[0].products.length != 0 && value.length < 3) {
            resetSearch()
        }
    };

    const categoryList = Object.entries(Categories).map(([key, name]) => ({ key, name }));

    const fuse = new Fuse(categoryList, {
        keys: ['name'],
        threshold: 0.3,  // Lower values mean more exact matches; 0.3 is generally good for fuzzy matches
        distance: 100    // Higher values allow more flexibility in terms of character differences
    });

    function findCategoryMatches(input: string): { key: string; name: string }[] {

        const results = fuse.search(input);
        return results.map(result => result.item);
    }

    function clearSearch() {
        // setResults({ products: [], searchCategories: [] });
        resetSearch()
        setKeyword('')

    }



    return (
        <div className="searchBar" ref={searchBarRef}>
            <div className="input-wrapper">
                <img src={searchIconDark} alt="Search icon" />
                <input placeholder='Type to search products' value={keyword} onChange={(e) => { handleChange(e.target.value) }} onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        navigate("/" + i18n.resolvedLanguage + t('navbar.routes.search') + "/" + keyword)
                        // setKeyword('')
                    }
                }} />
                <div className={`results-list  ${clickSearch || clickSearchMobile ? 'change ' : ''} ${keyword !== '' && 'show'}`}>
                    {/* <div className={`results-list ${clickSearch && keyword !== '' ? 'change' : 'show'}`}> */}
                    {/* <div className={`results-list ${keyword !== '' && 'show'}`}> */}
                    {loading ? <>{t('misc.loading')}...</> : <>

                        {
                            categoriesStore.length > 0 &&
                            <>
                                <ParagraphComponent>{t('misc.categories')}</ParagraphComponent>
                                <div className="results-categories-content">
                                    {
                                        categoriesStore.length > 0 && categoriesStore.map((searchCategory, id) => {
                                            return (<NavLink onClick={() => {
                                                setKeyword('')
                                            }} key={id} to={"/" + i18n.resolvedLanguage + convertChargerCategoryLink(searchCategory.key) + "/" + t(`solarEquipment.urlCategories.${searchCategory.key}`)}>{searchCategory.name}</NavLink>);
                                        })

                                    }</div>
                            </>
                        }


                        <div className="resultsTitle">
                            {keyword !== '' && !loading && searchResults.data[0].products.length > 0 && (
                                (<>
                                    <ParagraphComponent>{t('navbar.mainLinks.products')}</ParagraphComponent>
                                    <ParagraphComponent><NavLink className="seeAll" onClick={() => {
                                       if(setClickSearch) setClickSearch(true);
                                       if(setClickSearchMobile) setClickSearchMobile(true)
                                        // setKeyword('')
                                        // setClickSearch(true)
                                        // }} to={"/" + i18n.resolvedLanguage + t('navbar.routes.search') + "/" + keyword}>{t('misc.seeAll') + " (" + searchResults.data.products.length + "+)"}</NavLink></ParagraphComponent>
                                    }} to={"/" + i18n.resolvedLanguage + t('navbar.routes.search') + "/" + keyword}>{t('misc.seeAll') + " (" + searchResults.pagination.total + ")"}</NavLink></ParagraphComponent>

                                </>)
                            )}

                        </div>
                        {Object.keys(searchResults.data).map((pageKey) =>
                            searchResults.data[(Number(pageKey))].products.length > 0 ? (searchResults.data[(Number(pageKey))].products.map((product: searchProductType) => {
                                if (Number(product.price) > 0) return <SearchResultComponent result={product} key={product.id} clearSearch={clearSearch} />

                            }))
                                : (
                                    keyword !== '' && !loading && (
                                        (<ParagraphComponent white>{t('search.noProducts')}</ParagraphComponent>)
                                    )
                                )
                        )

                        }</>}
                </div>
                {keyword && <img src={closeIcon} alt="Close icon" onClick={() => { 
                    clearSearch() 
                    }} />}
            </div>
        </div>
    )
}

export function renderCategory(currentCategory: string) {
    if (currentCategory as keyof CategoryEquipment) {
        return (Categories[currentCategory as keyof CategoryEquipment]);
    } else return ('');




}

export default SearchBarComponent