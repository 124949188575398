import { Helmet } from 'react-helmet';
import './references.style.scss'
import { ContainerComponent, ImageSwiper, ParagraphComponent, SubtitleComponent, TitleComponent } from '../../components';
import { Trans, useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player'

import f11 from '../../assets/images/references/projekt1/1f1.JPG';
import f12 from '../../assets/images/references/projekt1/1f2.JPG';
import f13 from '../../assets/images/references/projekt1/1f3.jpg';
import f14 from '../../assets/images/references/projekt1/1f4.jpg';

import f21 from '../../assets/images/references/projekt1/2f1.JPG';
import f22 from '../../assets/images/references/projekt1/2f2.JPG';
import f23 from '../../assets/images/references/projekt1/2f3.jpg';
import f24 from '../../assets/images/references/projekt1/2f4.jpg';
import f25 from '../../assets/images/references/projekt1/2f5.jpg';
import f26 from '../../assets/images/references/projekt1/2f6.jpg';

import f31 from '../../assets/images/references/pula3.JPG';
import f32 from '../../assets/images/references/projekt1/3f2.jpg';
import f33 from '../../assets/images/references/projekt1/3f3.JPG';
import f34 from '../../assets/images/references/projekt1/3f4.jpg';
import f35 from '../../assets/images/references/projekt1/3f5.jpeg';
import f36 from '../../assets/images/references/projekt1/3f6.jpeg';

function ReferencePlinara() {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="referencePlinara" >
        <Helmet>
          <title>{t('project1.meta.title')}</title>
          <meta name="description" content={t('project1.meta.description')} />
        </Helmet>
        <ContainerComponent>
          <Trans>
            <h1><TitleComponent centered smaller style={{ marginTop: '3rem' }}>{t('project1.title')}</TitleComponent></h1>
            <h3><SubtitleComponent>{t('project1.subtitle')}</SubtitleComponent></h3>
            <ReactPlayer className="videoPlayer" playing={true} controls={true} autoplay muted={true} url={process.env.PUBLIC_URL + '/videos/Projekt1.mp4'} />

            <div className="specifications">
              <h3><SubtitleComponent medium white large semiBold>{t('project1.specifications.title')}</SubtitleComponent></h3>
              <ParagraphComponent>{t('project1.specifications.content')}</ParagraphComponent>
            </div>
            <div className="phases">
              <div className="phaseNumber">1</div>
              <ParagraphComponent>{t('project1.phases.1')}</ParagraphComponent>
              <ImageSwiper images={[f11, f12, f13, f14]} thumbs />
              <div className="phaseNumber">2</div>
              <ParagraphComponent>{t('project1.phases.2')}</ParagraphComponent>
              <ImageSwiper images={[f21, f22, f23, f24, f25, f26]} thumbs />
              <div className="phaseNumber">3</div>
              <ParagraphComponent>{t('project1.phases.3')}</ParagraphComponent>
              <ImageSwiper images={[f31, f32, f33, f34, f35, f36]} thumbs />
            </div>
          </Trans>
        </ContainerComponent>
      </div>
    </>
  )
}

export default ReferencePlinara