import React, { useEffect, useState } from 'react'
import axios from 'axios';
import ReactModal from 'react-modal';
import { useForm } from "react-hook-form";
import { ParagraphComponent, SubtitleComponent, TitleComponent, ButtonComponent } from '../';
import { useTranslation } from 'react-i18next';
import { Product, data } from '../productCard/data';

import './enquiryModal.style.scss'

//@ts-ignore
import closeIcon from '../../assets/icons/closeIcon.png';
//@ts-ignore
import checkIcon from '../../assets/icons/checkIcon.svg';
//@ts-ignore
import errorIcon from '../../assets/icons/errorIcon.svg';
import useCartStore from '../../store/cartStore';


interface Props {
    openModal: boolean;
    closeModal: any;

}

type FormData = {
    name: string;
    email: string;
    phoneNumber: string;
    selectedProducts: Product[];
    messageContent: string;
    dataProtection: boolean;
    terms: boolean;
    orderNo?: string;
};

const API_PATH = 'https://tesenergy.hr/api/contact/action_page.php';

function EnquiryModalComponent(props: Props) {
    const { t } = useTranslation();
    const { openModal, closeModal } = props;
    const [showModal, setShowModal] = useState(false);
    let totalPrice = 0;
    const { cart, removeFromCart, updateQuantity, clearCart } = useCartStore();

    const {
        register,
        handleSubmit,
        reset,
        formState,
        formState: { errors },
        formState: { isSubmitSuccessful },
        setValue
    } = useForm<FormData>();

    const [message, setMessage] = useState(
        {
            name: '',
            email: '',
            phoneNumber: '',
            selectedProducts: cart,
            messageContent: '',
            mailSent: false,
            error: null
        }
    )

    useEffect(() => {
        if (openModal == false) {
            reset(message)
            setMessage({ name: '', email: '', phoneNumber: '', selectedProducts: [], messageContent: '', mailSent: false, error: null });
        } else if (openModal === true) {
            setValue("selectedProducts", cart);
            // setMessage({ name: '', email: '', phoneNumber: '', selectedProducts: cart, messageContent: '', mailSent: false, error: null });
        }
        if (formState.isSubmitSuccessful) {
            // reset(message)
            setMessage({ name: '', email: '', phoneNumber: '', selectedProducts: [], messageContent: '', mailSent: true, error: null });
        }

    }, [openModal, closeModal, isSubmitSuccessful]);

    function onSubmit(data: FormData) {
        try {
            if (data.selectedProducts == null) throw Error
            setMessage({ ...message, name: data.name, email: data.email, phoneNumber: data.phoneNumber, selectedProducts: data.selectedProducts, messageContent: data.messageContent });
            axios({
                method: 'POST',
                url: `https://api.tesenergy.hr/products/orders`,
                headers: { 'content-type': 'application/json' },
                data: data
            })
                .then(result => {
                    if (result.data && result.status == 200) {
                        data.orderNo = result.data
                        axios({
                            method: 'post',
                            url: `${API_PATH}`,
                            headers: { 'content-type': 'application/json' },
                            data: data
                        })
                            .then(result => {
                                setMessage({ ...message, mailSent: result.data.sent })
                            })
                            .catch(error => {
                                setMessage({ ...message, error: error.message, mailSent: error.message })
                            }
                            );
                    }

                    localStorage.setItem('cart', '[]');
                    clearCart();
                })
                .catch(error => {
                    setMessage({ ...message, error: error.message, mailSent: error.message })
                }
                );

        }
        catch (error: any) {
            setMessage({ ...message, error: error.message, mailSent: error.message })
        }

    }

    return (
        <ReactModal
            isOpen={openModal}
            className="modal enquiryModal"
            overlayClassName="overlay"
            ariaHideApp={false}
            closeTimeoutMS={200}
            contentLabel="modal"
            onRequestClose={closeModal}
        >
            <div className="closeIconContainer">
                <img className='closeIcon' onClick={() => setShowModal(closeModal)
                } src={closeIcon} alt="Close Icon" />
            </div>
            <div className="formContainer">
                {<>
                    <h1><TitleComponent style={{ marginBottom: '0.5rem' }} moreSmaller medium centered>{t('contact.titleEnquiry')}<br /><span className='enquiryProduct'></span></TitleComponent></h1><ParagraphComponent style={{ marginBottom: '2rem' }}> </ParagraphComponent>
                </>}
                {message.mailSent && !message.error ?
                    <div className='confirmation'>
                        <img src={checkIcon} alt="Green check icon" />
                        <SubtitleComponent style={{ marginTop: '1rem', textAlign: 'center' }}>{t('contact.form.orderConfirmation')}</SubtitleComponent>
                    </div>
                    : message.mailSent || message.error ?
                        <div className='confirmation'>
                            <img src={errorIcon} alt="Red error icon" />
                            <SubtitleComponent style={{ marginTop: '1rem', textAlign: 'center' }}>{message.error}</SubtitleComponent>
                            <ButtonComponent onClick={() => { setMessage({ name: '', email: '', phoneNumber: '', selectedProducts: [], messageContent: '', mailSent: false, error: null }) }}>{t('contact.form.tryAgain')}</ButtonComponent>
                        </div>
                        : !message.mailSent && !formState.isSubmitSuccessful &&
                        <form className="emailForm" onSubmit={handleSubmit(onSubmit)} action="../../api/contact/action_page.php" method='post'>
                            <input type="text" id="name" placeholder={t('contact.form.name')} {...register("name", { required: true, pattern: /^[a-zA-Z]+(?:[' -][a-zA-Z]+)*$/ })} />
                            {errors?.name?.type === "required" ? (
                                <p className="error">{t('contact.form.nameError')}</p>
                            ) :
                                errors?.name?.type === "pattern" && (
                                    <p className="error">{t('contact.form.nameError')}</p>
                                )}
                            <input type="text" id="email" placeholder={t('contact.form.email')} {...register("email", { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })} />
                            {errors?.email?.type === "required" ? (
                                <p className="error">{t('contact.form.emailError')}</p>
                            ) :
                                errors?.email?.type === "pattern" && (
                                    <p className="error">{t('contact.form.emailError')}</p>
                                )}
                            <input type="text" id="phoneNumber" placeholder={t('contact.form.phone')} {...register("phoneNumber", { required: true, pattern: /^\+?[0-9\s\-()]{7,15}$/ })} />
                            {errors?.phoneNumber?.type === "required" ? (
                                <p className="error">{t('contact.form.phoneError')}</p>
                            ) :
                                errors?.phoneNumber?.type === "pattern" && (
                                    <p className="error">{t('contact.form.phoneError')}</p>
                                )}
                            <ParagraphComponent style={{ marginBottom: '1rem', marginTop: '1.5rem', fontFamily: 'Roboto-bold' }}>{t('contact.form.selectedProducts')}</ParagraphComponent>
                            {cart.map((value, index) => {
                                if (value.quantity) {
                                    totalPrice = totalPrice + value.price * value.quantity
                                }
                                return (<div key={value.id} className="productRow">
                                    <div className="productLeft">
                                        <div className="productImage">
                                            <div className={`imgBackground ${value.image === '' && 'noImgBg'}`}> {value.image === '' ? '' : <img src={value.image} alt={`Product image of ${value.title}`} />}</div>
                                        </div>
                                        <div className="leftContent">
                                            <div className="productTitle">
                                                <h2><ParagraphComponent className='productTitle' >{value.title}</ParagraphComponent></h2>
                                            </div>
                                            {/* <div className="productPrice">
                                                <ParagraphComponent white >{value.price} &euro;</ParagraphComponent>
                                            </div> */}

                                        </div>

                                    </div>
                                    <div className="productRight">
                                        <div className="rightContent">
                                            <div className="productQuantity">
                                                <input type='number' onChange={(event) => { updateQuantity(value.id, Number(event.target.value)) }} value={value.quantity} max={value.stock} min={0} />
                                            </div>
                                            <div className="productRemove">
                                                <ParagraphComponent onClick={() => { removeFromCart(value.id) }}>{t('navbar.cart.remove')}</ParagraphComponent>
                                            </div>
                                        </div>

                                    </div>
                                </div>)
                            })}

                            {/* <ParagraphComponent bold style={{ marginTop: '2rem', marginBottom: '2rem' }}>{t('contact.form.retailPrice')} <span className='totalPriceEnquiry'>{totalPrice.toFixed(2).toString()} &euro;</span> </ParagraphComponent> */}

                            <textarea style={{ height: '8rem' }} id="subject" placeholder={t('contact.form.msg')} {...register("messageContent")}></textarea>
                            {/* {errors?.messageContent?.type === "minLength" && (
                                <p className="error">{t('contact.form.msgError')}</p>
                            )} */}
                            <ParagraphComponent className={errors?.dataProtection?.type === "required" && 'error'}>
                                <input type="checkbox" style={{ marginRight: '0.5rem' }} id="dataProtection" {...register("dataProtection", { required: true })} />
                                <a style={{ textDecoration: 'underline' }} href="https://tesenergy.hr/assets/pdfs/privacy.pdf">{t('contact.form.dataProtection')}</a>
                            </ParagraphComponent>
                            {cart.length > 0 ? <ButtonComponent style={{ marginBottom: '2rem', marginTop: '1rem' }}><input type="submit" value={t('contact.form.button')} /></ButtonComponent> : ''}

                        </form>
                }

            </div>

        </ReactModal >
    )
}

export default EnquiryModalComponent;