import i18next from 'i18next';
import { Trans, withTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useParams } from 'react-router';
import { About, Home, Login, Equipment, Software, Solar, Chargers, HeatPumps, MountingSystems, NotFound, References, Diagnostics, SolarPanels, Inverters, Batteries, Cables, EvAccessories, EvChargers, ProductPage, ReferencePlinara, ReferenceABS, Rental, ProjectDesign, Deployment, Search } from '../pages';

function PathGenerator({ t }: { t: any }) {

  const Paths = [
    //   {
    //   path: "/",
    //   Element: <App />,
    // },
    {
      // index: true,
      path: t('navbar.routes.home'),
      Element: <Home />,
    },
    {
      path: t('navbar.routes.about'),
      Element: <About />,
    },
    {
      path: t('navbar.routes.login'),
      Element: <Login />,
    },
    {
      path: t('navbar.routes.software'),
      Element: <Software />,
    },
    // {
    //   path: t('navbar.routes.equipment'),
    //   Element: <Equipment categoryFromHome='' />,
    // },
    {
      path: t('navbar.routes.equipment') + t('navbar.routes.solarPanels'),
      Element: <SolarPanels />,
    },
    {
      path: t('navbar.routes.equipment') + t('navbar.routes.inverters'),
      Element: <Inverters />,
    },
    {
      path: t('navbar.routes.equipment') + t('navbar.routes.batteries'),
      Element: <Batteries />,
    },
    {
      path: t('navbar.routes.equipment') + t('navbar.routes.cables'),
      Element: <Cables />,
    },
    {
      path: t('navbar.routes.chargers') + t('navbar.routes.evChargers'),
      Element: <EvChargers />,
    },
    {
      path: t('navbar.routes.chargers') + t('navbar.routes.chargerAccessories'),
      Element: <EvAccessories />,
    },
    {
      path: t('navbar.routes.solar'),
      Element: <Solar />,
    },
    {
      path: t('navbar.routes.heatPumps'),
      Element: <HeatPumps />,
    },
    {
      path: t('navbar.routes.mountingSystems'),
      Element: <MountingSystems />,
    },
    {
      path: t('navbar.routes.references'),
      Element: <References />,
    },
    {
      path: t('navbar.routes.project1'),
      Element: <ReferencePlinara />,
    },
    {
      path: t('navbar.routes.project2'),
      Element: <ReferenceABS />,
    },
    {
      path: t('navbar.routes.diagnostics'),
      Element: <Diagnostics />,
    },
    {
      path: t('navbar.routes.rental'),
      Element: <Rental />,
    },
    {
      path: t('navbar.routes.projectDesign'),
      Element: <ProjectDesign />,
    },
    {
      path: t('navbar.routes.deployment'),
      Element: <Deployment />,
    },
    {
      path: t('navbar.routes.search')+"/:keyword",
      Element: <Search />,
    },
    // {
    //   path: t('navbar.routes.chargers'),
    //   Element: <Chargers />,
    // },
    // {
    //     path:'/electro',
    //     Element:<Electro />
    // },
    // {
    //     path:'/automatization',
    //     Element:<Automatization />
    // },
    {
      path: '*',
      Element: <NotFound />,
    }
    ,]

  return (
    <Routes>
      <Route path={"/hr"} >
        {Paths.map((val: any, id: any) => {
          return (
            <Route key={id} path={"/hr" + val.path} element={val.Element} />
          )
        })}
        <Route path={"/hr" + t('navbar.routes.equipment')} >
          <Route path={"/hr" + t('navbar.routes.equipment')} element={<Equipment />} />
          <Route path={"/hr" + t('navbar.routes.equipment') + "/:id"} element={<ProductPage />} />
          <Route path={"/hr" + t('navbar.routes.equipment') + "/" + t('solarEquipment.urlCategories.solar_panels')} element={<SolarPanels />} >
          </Route>
          {/* <Route path={"/hr" + t('navbar.routes.equipment') + "/" + t('solarEquipment.urlCategories.solar_panels') + "/:id"} element={<ProductPage />} /> */}
          <Route path={"/hr" + t('navbar.routes.equipment') + "/" + t('solarEquipment.urlCategories.inverters')} element={<Inverters />} >
          </Route>
          <Route path={"/hr" + t('navbar.routes.equipment') + "/" + t('solarEquipment.urlCategories.batteries')} element={<Equipment />} />
          {/* <Route path={"/hr" + t('navbar.routes.equipment') + "/" + t('solarEquipment.urlCategories.inverter_accessories')} element={<Equipment />} /> */}
          {/* <Route path={"/hr" + t('navbar.routes.equipment') + "/" + t('solarEquipment.urlCategories.battery_accessories')} element={<Equipment />} /> */}
          <Route path={"/hr" + t('navbar.routes.equipment') + "/" + t('solarEquipment.urlCategories.cables_and_connectors')} element={<Equipment />} />
          <Route path={"/hr" + t('navbar.routes.equipment') + "/" + t('solarEquipment.urlCategories.automatization')} element={<Equipment />} />

          {/* Temporary rerouting TODO: Change in future */}
          <Route
            path={"/hr" + t('navbar.routes.equipment') + "/" + t('solarEquipment.urlCategories.inverter_accessories')}
            element={<Navigate replace to={"/hr" + t('navbar.routes.equipment') + t('navbar.routes.inverters')} />} />
          <Route
            path={"/hr" + t('navbar.routes.equipment') + "/" + t('solarEquipment.urlCategories.battery_accessories')}
            element={<Navigate replace to={"/hr" + t('navbar.routes.equipment') + t('navbar.routes.batteries')} />} />




        </Route>
        <Route path={"/hr" + t('navbar.routes.chargers')} >
          <Route path={"/hr" + t('navbar.routes.chargers')} element={<Chargers />} />
          <Route path={"/hr" + t('navbar.routes.chargers') + "/" + t('chargersEquipment.urlCategories.ac')} element={<Chargers />} />
          <Route path={"/hr" + t('navbar.routes.chargers') + "/" + t('chargersEquipment.urlCategories.dc')} element={<Chargers />} />
          {/* <Route
            path={"/hr" + t('navbar.routes.chargers') + "/" + t('solarEquipment.urlCategories.charger_accessories')}
            element={<Navigate replace to={"/hr" + t('navbar.routes.chargers')} />} /> */}
        </Route>
        <Route path={"/hr" + t('navbar.routes.chargers') + "/" + t('chargersEquipment.urlCategoriesDropdown.chargerAccessories')} element={<EvAccessories />} />

        <Route path={"/hr" + t('navbar.routes.references')} element={<References />}></Route>
      </Route>
      <Route path={"/en"} >
        {Paths.map((val: any, id: any) => {
          return (
            <Route key={id} path={"/en" + val.path} element={val.Element} />
          )
        })}
        <Route path={"/en" + t('navbar.routes.equipment')} >
          <Route path={"/en" + t('navbar.routes.equipment')} element={<Equipment />} />
          <Route path={"/en" + t('navbar.routes.equipment') + "/:id"} element={<ProductPage />} />

          <Route path={"/en" + t('navbar.routes.equipment') + "/" + t('solarEquipment.urlCategories.solar_panels')} element={<Equipment />} />
          {/* <Route path={"/en" + t('navbar.routes.equipment') + "/" + t('solarEquipment.urlCategories.solar_panels') + "/:id"} element={<ProductPage />} /> */}

          <Route path={"/en" + t('navbar.routes.equipment') + "/" + t('solarEquipment.urlCategories.inverters')} element={<Equipment />} />
          <Route path={"/en" + t('navbar.routes.equipment') + "/" + t('solarEquipment.urlCategories.batteries')} element={<Equipment />} />
          <Route path={"/en" + t('navbar.routes.equipment') + "/" + t('solarEquipment.urlCategories.inverter_accessories')} element={<Equipment />} />
          <Route path={"/en" + t('navbar.routes.equipment') + "/" + t('solarEquipment.urlCategories.battery_accessories')} element={<Equipment />} />
          <Route path={"/en" + t('navbar.routes.equipment') + "/" + t('solarEquipment.urlCategories.cables_and_connectors')} element={<Equipment />} />
          <Route path={"/en" + t('navbar.routes.equipment') + "/" + t('solarEquipment.urlCategories.automatization')} element={<Equipment />} />

          {/* Temporary rerouting TODO: Change in future */}
          <Route path={"/en" + t('navbar.routes.equipment') + "/" + t('solarEquipment.urlCategories.inverter_accessories')} element={<Navigate replace to={"/en" + t('navbar.routes.equipment') + t('navbar.routes.inverters')} />} />
          <Route
            path={"/en" + t('navbar.routes.equipment') + "/" + t('solarEquipment.urlCategories.battery_accessories')}
            element={<Navigate replace to={"/en" + t('navbar.routes.equipment') + t('navbar.routes.battery_accessories')} />} />

        </Route>
        <Route path={"/en" + t('navbar.routes.chargers')} >
          <Route path={"/en" + t('navbar.routes.chargers')} element={<Chargers />} />
          <Route path={"/en" + t('navbar.routes.chargers') + "/" + t('chargersEquipment.urlCategories.ac')} element={<Chargers />} />
          <Route path={"/en" + t('navbar.routes.chargers') + "/" + t('chargersEquipment.urlCategories.dc')} element={<Chargers />} />
        </Route>
        <Route path={"/en" + t('navbar.routes.chargers') + "/" + t('chargersEquipment.urlCategoriesDropdown.chargerAccessories')} element={<EvAccessories />} />

      </Route>
      <Route path={"/en" + t('navbar.routes.references')} element={<References />}>
        <Route path={"/en" + t('navbar.routes.references')} element={<References />} />
      </Route>

      {/* <Route path={"*"} element={<Navigate replace to={"/" + i18next.resolvedLanguage + t('navbar.routes.home')} />} /> */}
      <Route path={"/*"} element={<Navigate replace to={"/" + i18next.resolvedLanguage + t('navbar.routes.notFound')} />} />
      <Route path={"/"} element={<Navigate replace to={"/" + i18next.resolvedLanguage + t('navbar.routes.home')} />} />
      <Route path={"/" + i18next.resolvedLanguage + "/"} element={<Navigate replace to={"/" + i18next.resolvedLanguage + t('navbar.routes.home')} />} />
      <Route path={"/" + i18next.resolvedLanguage + "/*"} element={<Navigate replace to={"/" + i18next.resolvedLanguage + t('navbar.routes.notFound')} />} />
    </Routes>
  )

}
export default withTranslation()(PathGenerator);


