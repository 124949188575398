import React from 'react'

//@ts-ignore
import './typographyStyles.scss'

interface Props {
    children: React.ReactNode;
    white?: boolean;
    max?: boolean;
    centered?: boolean;
    secondary?: boolean;
    raleway?: boolean;
    bold?: boolean;
    semiBold?: boolean;
    medium?: boolean;
    green?: boolean;
    style?: any;
    large?: any;
    className?: any;
}

export default function SubtitleComponent(props: Props) {
    const { children, white, max, centered, secondary, raleway, bold, semiBold, medium, green, style, large, className
    } = props;

    return (
        <p className={`subtitle 
        ${white && 'subtitle-white'}
        ${max && 'subtitle-max'}
        ${secondary && 'subtitle-secondary'}
        ${centered && 'subtitle-centered'}
        ${semiBold && 'subtitle-semiBold'}
        ${medium && 'subtitle-medium'}
        ${green && 'subtitle-green'}
        ${large && 'subtitle-large'}
        ${raleway && 'subtitle-raleway'}
        ${bold && 'subtitle-bold'}
        ${className}`}
            style={style}>
            {children}
        </p>
    )
}

