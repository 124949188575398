import { renderCategory, responseDataType } from './searchBar.component'
import searchIconDark from '../../assets/icons/searchIconDark.svg';
import { ParagraphComponent } from '../typography';
import { decode } from 'html-entities';
import { useNavigate } from 'react-router';
import i18n from '../../i18n';
import { t } from 'i18next';
import { Dispatch, SetStateAction } from 'react';
import Categories, { CategoryEquipment } from '../../pages/equipment/categories';
import { searchProductType } from '../../store/searchStore';
import imagePlaceholder from '../../assets/images/imagePlaceholder.svg';

interface Props {
    result: searchProductType,
    clearSearch: () => void
}
function SearchResultComponent(props: Props) {
    const { result, clearSearch } = props
    const navigate = useNavigate();
    return <div className='search-result' onClick={(e) => { navigate("/" + i18n.resolvedLanguage + t('navbar.routes.equipment') + "/" + result.id); clearSearch() }} key={result.id}>
        <div className="search-result-item">
            <div className="result-image">
                <img src={result.image === '' ? imagePlaceholder : result.image} alt={`Product image of ${result.title}`} />
            </div>
            <div className="result-content">
                <ParagraphComponent medium white>{result.title}</ParagraphComponent>
                <ParagraphComponent>{result.category &&
                    renderCategory(result.category)} - {result.brand}</ParagraphComponent>
            </div>

        </div>
    </div>
}

export default SearchResultComponent