import { useNavigate, useParams } from 'react-router';
import { ButtonComponent, ContainerComponent, ParagraphComponent, ProductCardSkeletonComponent, SubtitleComponent, TitleComponent } from '../../components'
import './search.style.scss'
import { useSearchStore } from '../../store';
import i18n from '../../i18n';
import { t } from 'i18next';
import SearchBarComponent, { renderCategory } from '../../components/searchBar/searchBar.component';
import imagePlaceholder from '../../assets/images/imagePlaceholder.svg';
import { NavLink } from 'react-router-dom';
import { convertChargerCategoryLink } from '../equipment/categories';
import { useEffect, useState } from 'react';
import { emptySearchResult, searchProductType, SearchResult } from '../../store/searchStore';
import { useTranslation } from 'react-i18next';
import ProductPageSkeletonComponent from '../productPage/productPageSkeleton.component';
import SearchPageSkeletonComponent from './searchPageSkeleton.component';

function Search() {
    // const { keyword = '' } = useParams();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const { searchResults, fetchSearchResults, loading, categoriesStore, setLoading, error, resetSearch, keyword, setKeyword } = useSearchStore();
    const [tempResults, setTempResults] = useState<SearchResult>(emptySearchResult)
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [click, setClick] = useState(false)


    useEffect(() => {

        if (keyword == '' && tempResults.data[currentPage - 1].products.length < 1) {

            const pathname = new URL(window.location.href).pathname;
            const parts = pathname.split('/');
            const searchTerm = parts.slice(-1)[0];

            if (searchTerm) {
                setKeyword(searchTerm)
                fetchSearchResults(searchTerm, 0)

            }
        }

        // if (searchResults.data.products.length < 1 && keyword !== '') {
        //     fetchSearchResults(keyword, 0)
        // }
    }, [])

    useEffect(() => {
        // if (searchResults.data.products.length < 1 && keyword !== '') {
        //     fetchSearchResults(keyword, 0)
        // }
        setClick(true)

    }, [])

    useEffect(() => {

        setTempResults(searchResults)
        setTotalPages(searchResults.pagination.page_count ? searchResults.pagination.page_count : 0)
    }, [searchResults])

    const categoryFilter = (category: string) => {
        let filteredResult: searchProductType[] = []

        Object.keys(tempResults.data).map((pageKey) =>
            // tempResults.data[(Number(pageKey))].products.map((product: searchProductType) => {
            filteredResult = searchResults.data[(Number(pageKey))].products.filter((product) => { return product.category === category })
            // })
        )

        setTempResults({
            ...searchResults, // Ensure to retain other properties of data if necessary
            data: { [currentPage - 1]: { products: filteredResult, searchCategories: searchResults.data[currentPage].searchCategories } }
        });

    }

    function resetFilter() {
        setTempResults(searchResults)
    }


    const handlePageChange = (page: number) => {


        if (page >= 1 && page <= totalPages) {

            if (keyword && !tempResults.data[page - 1]) fetchSearchResults(keyword, page - 1)

            setCurrentPage(page)

        }
    };

    useEffect(() => {
        // if (keyword && tempResults.data[currentPage - 1].products.length<0) fetchSearchResults(keyword, currentPage - 1)
    }, [currentPage])

    return (
        <div className='search'>
            <ContainerComponent>
                <div className="searchBarContainer"><SearchBarComponent clickSearch={click} setClickSearch={setClick} /></div>
                <h1><SubtitleComponent large centered white bold style={{ marginTop: '3rem' }}>{t('search.searchResults')} "{keyword}"</SubtitleComponent></h1>

                {loading ?
                    <div className="results">
                        <div className="products">
                            <SearchPageSkeletonComponent />
                            <SearchPageSkeletonComponent />
                            <SearchPageSkeletonComponent />
                        </div>
                    </div>
                    :
                    // { loading ? tempResults.pagination.total > 1 ? "Loading" :
                    <div className="results">
                        {/* <div className="categories">
                                <SubtitleComponent white semiBold>Filters</SubtitleComponent>
                                <SubtitleComponent white>Categories</SubtitleComponent>
                                {

                                    Object.keys(tempResults.data).map((pageKey) =>
                                        tempResults.data[(Number(pageKey))].searchCategories.map((category, id) => {
                                            return (<ButtonComponent onClick={() => { categoryFilter(category) }} key={id} >{renderCategory(category)}</ButtonComponent>);

                                        }))

                                }
                                <ButtonComponent ghost onClick={() => { resetFilter() }} >Reset</ButtonComponent>
                            </div> */}
                        <div className="products">
                        <h3><SubtitleComponent white bold>{t('misc.categories')}</SubtitleComponent></h3>

                            {
                                categoriesStore.length > 0 ?
                                <>

                                    <div className="results-categories-content">
                                        {
                                            categoriesStore.length > 0 && categoriesStore.map((searchCategory, id) => {
                                                return (<ButtonComponent key={id} ghost>
                                                    <NavLink onClick={() => {
                                                        setKeyword('')
                                                    }} to={"/" + i18n.resolvedLanguage + convertChargerCategoryLink(searchCategory.key) + "/" + t(`solarEquipment.urlCategories.${searchCategory.key}`)}>{searchCategory.name}</NavLink>
                                                </ButtonComponent>);
                                            })

                                        }</div>
                                </>
                                : <ParagraphComponent>{t('search.noCategories')}</ParagraphComponent>
                            }
                            <h3><SubtitleComponent white bold >{t('navbar.mainLinks.products')}</SubtitleComponent></h3>

                            {tempResults.data[currentPage - 1].products.length > 0 ? tempResults.data[currentPage - 1].products?.map((result: searchProductType) => {
                                if (Number(result.price) > 0)
                                    return (
                                        <div className='searchPageResult' key={result.id}>
                                            <div className="imgDiv">
                                                <div className={`imgBackground ${result.image === undefined ? 'placeholderBg' : 'noImgBg'}`}>
                                                    {result.image === '' ? (
                                                        <img src={imagePlaceholder} alt={`Placeholder image`} />
                                                    ) : (
                                                        <img src={result.image} alt={`Product image of ${result.title}`} />
                                                    )}
                                                </div>
                                            </div>
                                            <div className="detailsDiv">
                                                <div className="productDetails">
                                                    <div className="productTitle">
                                                        <h2>
                                                            <SubtitleComponent white semiBold>{result.title}</SubtitleComponent>
                                                        </h2>
                                                    </div>
                                                    <div className="productBrand">
                                                        <h3>
                                                            <ParagraphComponent medium>
                                                                {result.category && (
                                                                    <NavLink
                                                                        to={
                                                                            '/' +
                                                                            i18n.resolvedLanguage +
                                                                            convertChargerCategoryLink(result.category ? result.category : '') +
                                                                            '/' +
                                                                            t(`solarEquipment.urlCategories.${result.category && result.category}`)
                                                                        }
                                                                    >
                                                                        {renderCategory(result.category) } 
                                                                    </NavLink>
                                                                )} - {result.brand}
                                                            </ParagraphComponent>
                                                        </h3>
                                                    </div>
                                                    <div className="productDescription">
                                                        <h3>
                                                            <ParagraphComponent>{result.description}</ParagraphComponent>
                                                        </h3>
                                                    </div>
                                                </div>
                                                <div className="productButtons">
                                                    <div className="buttons">
                                                        <NavLink to={'/' + i18n.language + t('navbar.routes.equipment') + '/' + result.id}>
                                                            <ParagraphComponent white>{`${t('misc.learnMore')} >`}</ParagraphComponent>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                            }): <ParagraphComponent>{`${t('search.noProducts')}`}</ParagraphComponent>
                        }


                            {/* Pagination Controls */}
                            {tempResults.data[currentPage - 1].products.length > 0 ? <div className="pagination">
                                <ButtonComponent onClick={() => { handlePageChange(currentPage - 1); window.scrollTo(0, 0) }} disabled={currentPage === 1} >
                                    {`<`}
                                </ButtonComponent>
                                {Array.from({ length: totalPages }, (_, index) => (
                                    <ButtonComponent
                                        key={index}
                                        onClick={() => handlePageChange(index + 1)}
                                        className={currentPage === index + 1 ? 'active' : ''}
                                    >
                                        {index + 1}
                                    </ButtonComponent>
                                ))}
                                <ButtonComponent onClick={() => { handlePageChange(currentPage + 1); window.scrollTo(0, 0) }} disabled={currentPage === totalPages} >
                                {`>`}
                                </ButtonComponent>
                            </div> : ""}

                        </div>
                    </div>
                    // : <ParagraphComponent>No products found</ParagraphComponent>
                }



            </ContainerComponent>
        </div>
    )
}

export default Search