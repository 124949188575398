import React from 'react'
import { Helmet } from 'react-helmet'
import { Trans, useTranslation } from 'react-i18next';
import { ContainerComponent, ParagraphComponent, SubtitleComponent, TitleComponent } from '../../components';
import '../diagnostics/diagnostics.style.scss'

import project from '../../assets/images/project.jpg'

function ProjectDesign() {
    const { t, i18n } = useTranslation();
    let projectTypes: string[] = i18n.t('projectDesign.projectTypes.types', { returnObjects: true });

    return (
        <div className='projectDesign diagnostics'>
            <Helmet>
                <title>{t('projectDesign.meta.title')}</title>
                <meta name="description" content={t('projectDesign.meta.description')} />
            </Helmet>
            <ContainerComponent>
                <Trans>
                    <h1><TitleComponent centered smaller>{t('projectDesign.title')}</TitleComponent></h1>
                    <div className="diagnosticsIntroText">
                        <h2><SubtitleComponent max>{t('projectDesign.subtitle')}</SubtitleComponent></h2>
                    </div>
                    <img className='diagnostics-image' src={project} alt='Project Planing' />
                    <h4><SubtitleComponent semiBold>{t('projectDesign.projectTypes.subtitle')}</SubtitleComponent></h4>
                    <ul className='projectTypes'>
                        {projectTypes.map((element, index) => (
                            <li key={index}><ParagraphComponent>{element}</ParagraphComponent></li>
                        ))}
                    </ul>

                </Trans>
            </ContainerComponent>
        </div>
    )
}

export default ProjectDesign