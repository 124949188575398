import { create } from 'zustand';
import { Pagination } from '../components/productCard/data';
import { set } from 'react-ga';
import Categories from '../pages/equipment/categories';
import Fuse from 'fuse.js';

export interface searchProductType {
  id: string;
  ref: string;
  label: string;
  description: string;
  image: string;
  price: string;
  stock: string;
  brand: string;
  title: string;
  category?: string;
  categoriesStore: { id: string, label: string }

}

export const emptySearchResult: SearchResult = {
  data: { 0: { products: [], searchCategories: [] } }, pagination: {}
}

export interface SearchResult {
  data: {
    [page: number]: {
      products: searchProductType[],
      searchCategories: []
    }
  };
  pagination: Pagination
}

interface Categories {
  key: string;
  name: string;
}

interface SearchStore {
  searchResults: SearchResult;
  fetchSearchResults: (keyword: string, page: number) => Promise<void>;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  keyword: string;
  setKeyword: (keyword: string) => void;
  categoriesStore: Categories[];
  setCategories: (categoriesStore: Categories[]) => void;
  error: string | null;
  resetSearch: () => void;

}

const backendUrl = process.env.REACT_APP_API_URL;

export function findCategoryMatches(input: string): Categories[] {

  const results = fuse.search(input);
  return results.map(result => result.item);
}

const useSearchStore = create<SearchStore>((set) => ({
  searchResults: emptySearchResult,
  loading: false,
  keyword: '',
  error: null,
  fetchSearchResults: async (keyword, page) => {
    set({ loading: true, error: null });

    fetch(`${backendUrl}/products/search/${keyword}/${page}`, {
      method: 'GET',
      headers: { 'Accept': 'application/json' },
    })
      .then((response) => { if (!response.ok) throw new Error(); return response.json() })
      .then((result) => {

        set((state) => ({
          searchResults: {
            // data: {
            //   ...state.searchResults.data, // Preserve existing pages
            //   [page]: result.data.products, // Add/overwrite the current page's products.
            // }
            data: {
              ...state.searchResults.data,
              [page]: {
                products: result.data[page].products,
                searchCategories: result.data[page].searchCategories
              }
            },
            pagination: result.pagination
          }
        }))

      }).then(() => {
        
        set({
          loading: false,
          categoriesStore: findCategoryMatches(keyword)
        })
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
        set({
          loading: true, error: error
        })
      })
  },
  setLoading: (loading) => set({ loading }),
  setKeyword: (keyword) => set({ keyword }),
  categoriesStore: [],
  setCategories: (categoriesStore) => set({ categoriesStore }),
  resetSearch: () => set({ searchResults: emptySearchResult, categoriesStore: []}),

}));

function removeDuplicates(products: searchProductType[]) {
  const uniqueProducts: searchProductType[] = [];
  const seenIds = new Set();

  products.forEach(product => {
    if (!seenIds.has(product.id)) {
      uniqueProducts.push(product);
      seenIds.add(product.id); // Add the product ID to the set
    }
  });

  return uniqueProducts;
}

const categoryList = Object.entries(Categories).map(([key, name]) => ({ key, name }));

const fuse = new Fuse(categoryList, {
  keys: ['name'],
  threshold: 0.3,  // Lower values mean more exact matches; 0.3 is generally good for fuzzy matches
  distance: 100    // Higher values allow more flexibility in terms of character differences
});




export default useSearchStore