import React, { useEffect, useState } from 'react'

//@ts-ignore
import './button.style.scss'
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

interface Props {
    children: React.ReactNode;
    rounded?: boolean;
    categoryId?: string;
    categoryButton?: boolean;
    ghost?: boolean;
    disabled?: boolean;
    white?: boolean;
    mini?: boolean;
    image?: boolean;
    green?: boolean;
    style?: any;
    wide?: boolean;
    className?: any;
    onClick?: any;
}



export default function ButtonComponent(props: Props) {
    const { children, categoryId, rounded, categoryButton, ghost, white, mini, image, disabled, wide, style, className, green, onClick } = props;
    const { t } = useTranslation();

    const [childrenButton, setChildrenButton] = useState(children);
    const [isClickable, setIsClickable] = useState(true);
    const [isAddedToCart, setIsAddedToCart] = useState(false);


    useEffect(() => {
        if (!isAddedToCart) {
            setChildrenButton(children);

        }
    }, [children, isAddedToCart]);

    function atcButtonClicked() {
        if (categoryId === 'addToCart') {
            setChildrenButton(t('productsCard.added'));
            setIsClickable(false); // Disable future clicks
            setIsAddedToCart(true); // Prevent resetting to the original text
        }
        // Revert text after 1 second
        setTimeout(() => {
            setChildrenButton(children);
            setIsClickable(true); // Re-enable clicking
        }, 2000);
    }

    function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
        if (isClickable) {
            atcButtonClicked();
            if (onClick) {
                onClick(event); // Call the passed onClick prop
            }
        }
    }

    return (
        <button id={categoryId} disabled={disabled} onClick={handleClick} style={style} className={`
        button 
        ${rounded ? 'button-rounded' : ''}
        ${categoryButton ? 'button-categoryButton' : ''}
        ${white ? 'button-white' : ''}
        ${mini ? 'button-mini' : ''}
        ${wide ? 'button-wide' : ''}
        ${image ? 'button-image' : ''}
        ${green ? 'button-green' : ''}
        ${className}
        ${ghost ? 'button-ghost' : ''}
        `}>
            {childrenButton}
        </button>
    )
}
