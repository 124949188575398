import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next';
import { ContainerComponent, SubtitleComponent, TitleComponent } from '../../components';
import '../diagnostics/diagnostics.style.scss'

import f34 from '../../assets/images/references/projekt1/3f4.jpg';

function Deployment() {
    const { t, i18n } = useTranslation();

    return (
        <div className='deployment diagnostics'>
            <Helmet>
                <title>{t('deployment.meta.title')}</title>
                <meta name="description" content={t('deployment.meta.description')} />
            </Helmet>
            <ContainerComponent>
                <h1><TitleComponent centered smaller>{t('deployment.title')}</TitleComponent></h1>
                <div className="diagnosticsIntroText">
                    <h2><SubtitleComponent max>{t('deployment.subtitle')}</SubtitleComponent></h2>
                </div>
                <img className='diagnostics-image' src={f34} alt="Inverter" />

            </ContainerComponent>
        </div>
    )
}

export default Deployment