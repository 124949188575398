import { Helmet } from 'react-helmet'
import './productPage.style.scss'
import { ButtonComponent, ContainerComponent, ParagraphComponent, ProductCardComponent, ProductCardSkeletonComponent, SubtitleComponent, TitleComponent } from '../../components'
import { useCartStore, useProductStore } from '../../store';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

//@ts-ignore
import downloadIcon from '../../assets/icons/download.svg';
//@ts-ignore
import cartIcon from '../../assets/icons/cart.svg';
import imagePlaceholder from '../../assets/images/imagePlaceholder.svg';
import { Product } from '../../components/productCard/data';
import { CategoryEquipment, convertChargerCategoryLink } from '../equipment/categories';
import { NavLink } from 'react-router-dom';
import ProductPageSkeletonComponent from './productPageSkeleton.component';
import { t } from 'i18next';

function ProductPage() {
    const { t, i18n } = useTranslation();
    const { state } = useLocation();
    const { id } = useParams();
    const navigate = useNavigate();
    // const { category } = state;
    const { error, fetchProduct, product, resetProduct, solarPanels, fetchSolarPanels, batteries, fetchBatteries, inverters, fetchInverters, cables, fetchCables, chargers, charger_accessories, loading } = useProductStore();
    const { addToCart, toggleCart, setToogleCart } = useCartStore();

    const [productQuantity, setProductQuantity] = useState(1)
    const [tempProduct, setTempProduct] = useState<Product>();
    const [tempRecProducts, setTempRecProducts] = useState<Product[]>([]);
    const sleep = (ms: number) => new Promise(r => setTimeout(r, ms));

    let categoriesEquipment: CategoryEquipment = t('solarEquipment.categories')

    function addProductToCart(quantity: number) {
        if (product) { addToCart(product, quantity) }
    }

    function renderCategory(currentCategory: string) {
        if (currentCategory as keyof CategoryEquipment) {
            return (categoriesEquipment[currentCategory as keyof CategoryEquipment]);
        }
        else return ('');
    }

    const toggleCartExpansion = async () => {
        await sleep(200);
        setToogleCart(toggleCart);
    };

    useEffect(() => {
        const fetchCurrentProduct = async () => {
            fetchProduct(Number(id))

        }

        if (!tempProduct) { fetchCurrentProduct() }

        return () => {
            resetProduct()
        };

    }, [])

    useEffect(() => {
        if (product) setTempProduct(product)
    }, [product])


    useEffect(() => {
        if (error) navigate("/" + i18n.resolvedLanguage + t('navbar.routes.notFound'))
    }, [error])

    useEffect(() => {
        console.log(id);

    }, [])

    useEffect(() => {
        const fetchCurrentProduct = async () => {
            fetchProduct(Number(id))

        }

        if (tempProduct && tempProduct.id != id) { fetchCurrentProduct() }

        return () => {
            resetProduct()
        };

    }, [id])



    return (
        <div className='productPage'>
            {tempProduct && !loading ?
                <>
                    <Helmet>
                        <title>{tempProduct.brand + " - " + tempProduct.title}</title>
                        <meta name="description" content={tempProduct.desc} />
                    </Helmet>
                    <ContainerComponent>
                        <div className="productContainer">
                            <div className="imageDiv">
                                <img src={tempProduct.image === '' ? imagePlaceholder : tempProduct.image} alt={`Product image of ${tempProduct.title}`} />
                            </div>
                            <div className="contentDiv">
                                <div className="productInfo">
                                    <h2><SubtitleComponent className='productBrand'>
                                        <NavLink to={"/" + i18n.resolvedLanguage + convertChargerCategoryLink(tempProduct.categoryUrl ? tempProduct.categoryUrl : '') + "/" + t(`solarEquipment.urlCategories.${tempProduct.categoryUrl}`)}>{tempProduct.categoryUrl ? renderCategory(tempProduct.categoryUrl) : tempProduct.category} </NavLink>
                                        {tempProduct.brand ? '-' : null} {tempProduct.brand}
                                    </SubtitleComponent></h2>
                                    <h1><TitleComponent className='productTitle'>{tempProduct.title}</TitleComponent></h1>
                                    <ParagraphComponent className='productDescription'>{tempProduct.desc}</ParagraphComponent>
                                    <ParagraphComponent className='productSpecifications' dark><a href={tempProduct.url}>{t('productsCard.specifications')}<img className='downloadIcon' src={downloadIcon} /></a></ParagraphComponent>
                                    {/* <h3><SubtitleComponent className={`productPrice ${tempProduct.stock === 0 && 'outOfStockPrice'}`}>{tempProduct.price.toFixed(2).toString().replace(".", ",")} &euro;</SubtitleComponent></h3> */}
                                    <h4><ParagraphComponent className='productStock'>{tempProduct.stock ? (
                                        <>
                                            {t('productsCard.inStock')} {<span>{tempProduct.stock.toString()}</span>}
                                        </>
                                    ) : (
                                        t('productsCard.outOfStock')
                                    )}</ParagraphComponent></h4>
                                </div>
                                {tempProduct.stock ?
                                    <div className="buttonContainer">

                                        <ButtonComponent categoryId='addToCart' white onClick={() => { addProductToCart(productQuantity); toggleCartExpansion(); }}>{t('productsCard.addToCart')}<img className='cartIcon' src={cartIcon} />
                                        </ButtonComponent>
                                        <input type='number' max={tempProduct.stock} value={productQuantity} onChange={(event) => { setProductQuantity(Number(event.target.value)) }} min={1} />
                                    </div> : <ButtonComponent ghost style={{ marginTop: '1rem' }} onClick={() => { }}>{t('productsCard.outOfStock')}</ButtonComponent>}
                            </div>
                        </div>
                        {/* <div className="productCardContainer">
                        {product?.category === 'solar_panels' ? <ProductCardComponent data={solarPanels.products.slice(0, 3)} /> 
                        : product?.category === 'batteries' ? <ProductCardComponent data={batteries.products.slice(0, 3)} />
                        : product?.category === 'inverters' ? <ProductCardComponent data={inverters.products.slice(0, 3)} />
                        : product?.category === 'cables_and_connectors' ? <ProductCardComponent data={cables.products.slice(0, 3)} />
                        : product?.category === 'chargers' ? <ProductCardComponent data={chargers.products.slice(0, 3)} />
                        : product?.category === 'charger_accessories' ? <ProductCardComponent data={charger_accessories.products.slice(0, 3)} />
                        : ''}
                    </div> */}
                    </ContainerComponent>
                </>
                : tempProduct === undefined && loading ?
                    <ContainerComponent>
                        <ProductPageSkeletonComponent></ProductPageSkeletonComponent>
                    </ContainerComponent>
                    : tempProduct === undefined && !loading || error &&
                    <div className='notFound'>
                        <Helmet>
                            <title>{t('productPage.notFound')}</title>
                        </Helmet>
                        <TitleComponent medium>{t('productPage.t1')}</TitleComponent>
                        <TitleComponent moreSmaller medium >{t('productPage.t2')}</TitleComponent>
                        <div className="linksDiv">
                            <ParagraphComponent>
                                {t('navbar.cart.products')} <NavLink to={"/" + i18n.resolvedLanguage + t('navbar.routes.equipment')}>{t('navbar.cart.solarProducts')}</NavLink> {t('navbar.cart.or')} <NavLink to={"/" + i18n.resolvedLanguage + t('navbar.routes.chargers')}>{t('navbar.cart.EV')}</NavLink>
                            </ParagraphComponent>
                        </div>
                    </div>

            }
        </div>
    )
}



export default ProductPage