import { useEffect, useState } from 'react'
import { Product } from '../../../components/productCard/data';
import { sortProducts, sortProductsByStock } from '../Equipment';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { ButtonComponent, ContainerComponent, ParagraphComponent, ProductCardComponent, ProductCardSkeletonComponent, ProductModalComponent, SubtitleComponent, TitleComponent } from '../../../components';
import useProductStore, { findProductById } from '../../../store/productStore';
import { useParams } from 'react-router';

function SolarPanels() {
    const { solarPanels, loading, setLoading, fetchSolarPanels } = useProductStore();
    const [tempSolarProducts, setTempSolarProducts] = useState<Product[]>(solarPanels.products);
    const [currentPage, setCurrentPage] = useState(1)

    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (solarPanels.products.length === 0) fetchSolarPanels(0)
    }, [])

    useEffect(() => {
        const sortedProducts = sortProducts(solarPanels.products);
        setTempSolarProducts(sortedProducts);
    }, [solarPanels]);

    return (
        <>
            <div className="equipment" id="equipmentContainer">
                <Helmet>
                    <title>{t('solarEquipment.meta2')}</title>
                    <meta name="description" content={t('solarEquipment.meta.description')} />
                </Helmet>
                <ContainerComponent>
                    <h1><TitleComponent centered smaller style={{ marginTop: '3rem' }}>{t('solarEquipment.title2')}</TitleComponent></h1>
                    <SubtitleComponent centered>{t('solarEquipment.subtitle')}</SubtitleComponent>
                    {loading ?
                        <div className="productCardContainer">
                            <ProductCardSkeletonComponent />
                            <ProductCardSkeletonComponent />
                            <ProductCardSkeletonComponent />
                            <ProductCardSkeletonComponent />
                            <ProductCardSkeletonComponent />
                            <ProductCardSkeletonComponent />
                        </div> : <>

                            <div className="productCardContainer">
                                <ProductCardComponent data={tempSolarProducts} />
                            </div>
                        </>}
                    {solarPanels.pagination.page_count && currentPage <= solarPanels.pagination.page_count - 1 ?
                        <ButtonComponent style={{ margin: '0 auto', display: 'block' }} onClick={() => { setCurrentPage(currentPage + 1); fetchSolarPanels(currentPage) }}>Load more</ButtonComponent>
                        : ''}
                    {/* {solarPanels.pagination.total ? <ParagraphComponent style={{ color: 'white' }}>{tempSolarProducts.length} of {solarPanels.pagination.total} Products</ParagraphComponent> : ''} */}
                </ContainerComponent>
            </div>
        </>
    )
}
export default SolarPanels