import { Helmet } from 'react-helmet'
import './references.style.scss'
import { Trans, withTranslation } from 'react-i18next';
import { ButtonComponent, ContainerComponent, ImageSwiper, ReferenceModalComponent, SubtitleComponent, TitleComponent } from '../../components';
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Pagination, Thumbs } from "swiper";
import i18n from '../../i18n';
import { useNavigate } from 'react-router';

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

//@ts-ignore
import roof1ipk from '../../assets/images/references/roof1ipk.JPG';
//@ts-ignore
import roof2ipk from '../../assets/images/references/roof2ipk.JPG';
//@ts-ignore
import roof3ipk from '../../assets/images/references/roof3ipk.JPG';
//@ts-ignore
import charger1ipk from '../../assets/images/references/charger1ipk.JPG';
//@ts-ignore
import charger2ipk from '../../assets/images/references/charger2ipk.jpg';
//@ts-ignore
import inverteripk from '../../assets/images/references/inverteripk.JPG';

//@ts-ignore
import roof1crimmpi from '../../assets/images/references/roof1crimmpi.JPG';
//@ts-ignore
import roof2crimmpi from '../../assets/images/references/roof2crimmpi.JPG';
//@ts-ignore
import invertercrimmpi from '../../assets/images/references/invertercrimmpi.jpg';

//@ts-ignore
import cp1matiki from '../../assets/images/references/cp1matiki.JPG';
//@ts-ignore
import cp2matiki from '../../assets/images/references/cp2matiki.JPG';

//@ts-ignore
import krov1kliman from '../../assets/images/references/krov1kliman.JPG';
//@ts-ignore
import krov2kliman from '../../assets/images/references/krov2kliman.JPG';

//@ts-ignore
import hek1 from '../../assets/images/references/hek1.jpg';
//@ts-ignore
import hek2 from '../../assets/images/references/hek2.jpg';
//@ts-ignore
import hek3 from '../../assets/images/references/hek3.JPG';
//@ts-ignore
import hek4 from '../../assets/images/references/hek4.JPG';

//@ts-ignore
import krizanac1 from '../../assets/images/references/krizanac1.JPG';

//@ts-ignore
import krnica1 from '../../assets/images/references/krnica1.JPG';
//@ts-ignore
import krnica2 from '../../assets/images/references/krnica2.JPG';

//@ts-ignore
import krculi1_1 from '../../assets/images/references/krculi1_1.JPG';

//@ts-ignore
import krculi2_2 from '../../assets/images/references/krculi2_2.JPG';

//@ts-ignore
import peric1 from '../../assets/images/references/peric1.jpg';
//@ts-ignore
import peric2 from '../../assets/images/references/peric2.JPG';

//@ts-ignore
import buletic_1 from '../../assets/images/references/buletic_1.JPG';

//@ts-ignore
import jelenic1 from '../../assets/images/references/jelenic1.jpg';
//@ts-ignore
import jelenic2 from '../../assets/images/references/jelenic2.jpg';
//@ts-ignore
import jelenic3 from '../../assets/images/references/jelenic3.JPG';

//@ts-ignore
import pucic1 from '../../assets/images/references/pucic1.JPG';

//@ts-ignore
import buzet1 from '../../assets/images/references/buzet1.JPG';
//@ts-ignore
import buzet2 from '../../assets/images/references/buzet2.JPG';
//@ts-ignore
import buzet3 from '../../assets/images/references/buzet3.JPG';
//@ts-ignore
import buzet4 from '../../assets/images/references/buzet4.jpg';

//@ts-ignore
import vrsar11 from '../../assets/images/references/vrsar11.JPG';
//@ts-ignore
import vrsar12 from '../../assets/images/references/vrsar12.JPG';
//@ts-ignore
import vrsar21 from '../../assets/images/references/vrsar21.JPG';
//@ts-ignore
import vrsar22 from '../../assets/images/references/vrsar22.JPG';

//@ts-ignore
import pula1 from '../../assets/images/references/pula1.JPG';
//@ts-ignore
import pula2 from '../../assets/images/references/pula2.JPG';
//@ts-ignore
import pula3 from '../../assets/images/references/pula3.JPG';
//@ts-ignore
import pula4 from '../../assets/images/references/pula4.JPG';
//@ts-ignore
import f34 from '../../assets/images/references/projekt1/3f4.jpg';

//@ts-ignore
import a1 from '../../assets/images/references/projekt2/a1.jpg';
//@ts-ignore
import a2 from '../../assets/images/references/projekt2/a2.jpg';
//@ts-ignore
import a3 from '../../assets/images/references/projekt2/a3.JPG';
//@ts-ignore
import a4 from '../../assets/images/references/projekt2/a4.png';
//@ts-ignore
import a5 from '../../assets/images/references/projekt2/a5.JPG';
//@ts-ignore
import a6 from '../../assets/images/references/projekt2/a6.JPG';
//@ts-ignore
import a7 from '../../assets/images/references/projekt2/a7.jpg';
//@ts-ignore
import a8 from '../../assets/images/references/projekt2/a8.jpg';

//@ts-ignore
import m1 from '../../assets/images/references/m1.JPG';
//@ts-ignore
import m3 from '../../assets/images/references/m3.jpg';
//@ts-ignore
import m4 from '../../assets/images/references/m4.jpg';

import { useState } from 'react';
import type { Swiper as SwiperType } from 'swiper';

//@ts-ignore
import checkIcon from '../../assets/icons/checkIcon.svg';


function References({ t }: { t: any }) {

    const [openModal, setOpenModal] = useState(false);
    const [referenceContent, setReferenceContent] = useState(<></>);
    const [thumbsSwiper, setThumbsSwiper] = useState<SwiperType | null>(null);
    const navigate = useNavigate();


    function generateParagraphs(text: string): JSX.Element[] {
        const paragraphs = text.split('<br>').filter(paragraph => paragraph.trim() !== '');
        return paragraphs.map((paragraph, index) => {
            const parts = paragraph.split(':');
            return (
                <SubtitleComponent key={index} max><span className='referenceSpan'>{parts[0]}:</span>
                    {parts[1]}</SubtitleComponent>
            );
        });
    }

    return (
        <div className="references">
            <ReferenceModalComponent openModal={openModal} closeModal={() => setOpenModal(false)} referenceContent={referenceContent} />
            <Helmet>
                <title>{t('references.meta.title')}</title>
                <meta name="description" content={t('references.meta.description')} />
            </Helmet>
            <ContainerComponent>
                <Trans>
                    <h1><TitleComponent centered smaller>{t('references.title')}</TitleComponent></h1>
                    <div className="referenceIntroText">
                        <SubtitleComponent max>{t('references.subtitle')}<span><a style={{ margin: 0 }} href='mailto:info@tesenergy.hr'>info@tesenergy.hr</a></span>.</SubtitleComponent>
                    </div>
                    <div className="swiperContainer">
                        <h3 onClick={() => navigate("/" + i18n.language + t('navbar.routes.project1') + "/")}><SubtitleComponent white semiBold centered medium>{t('project1.title')}</SubtitleComponent></h3>
                        <ImageSwiper images={[pula1, pula2, f34, pula4]} thumbs={true} />
                        <ButtonComponent green onClick={() => navigate("/" + i18n.language + t('navbar.routes.project1') + "/")}>{t('misc.learnMore')}</ButtonComponent>
                    </div>

                    <div className="twoItemsContainer">
                        <div className="referencePreview" onClick={() => {
                            setReferenceContent(
                                <div className="swiperContainer">
                                    <h3><SubtitleComponent medium white centered large>{t('references.ipk.title')}</SubtitleComponent></h3>
                                    <ImageSwiper images={[roof1ipk, roof2ipk, roof3ipk, charger1ipk, charger2ipk, inverteripk]} thumbs={true} />

                                    <div className="projectDescription">
                                        <div>{generateParagraphs(t('references.ipk.subtitle1'))}</div>
                                    </div>
                                </div>); setOpenModal(true)
                        }} >
                            <h3><SubtitleComponent white semiBold centered medium>{t('references.ipk.title')}</SubtitleComponent></h3>
                            <img src={roof1ipk} />
                        </div>
                        <div className="referencePreview" onClick={() => {
                            setReferenceContent(
                                <div className="swiperContainer">
                                    <h3><SubtitleComponent medium white centered large>{t('references.crimpi.title')}</SubtitleComponent></h3>
                                    <ImageSwiper images={[roof1crimmpi, roof2crimmpi, invertercrimmpi]} thumbs={true} />

                                    <div className="projectDescription">
                                        <div>{generateParagraphs(t('references.crimpi.subtitle1'))}</div>
                                        {/* <SubtitleComponent max>{t('references.crimpi.subtitle2')}</SubtitleComponent> */}
                                    </div>
                                </div>);
                            setOpenModal(true)
                        }} >
                            <h3><SubtitleComponent white semiBold centered medium>{t('references.crimpi.title')}</SubtitleComponent></h3>
                            <img src={roof1crimmpi} />
                        </div>

                    </div>

                    <div className="swiperContainer">
                        <h3 onClick={() => navigate("/" + i18n.language + t('navbar.routes.project2') + "/")}><SubtitleComponent white semiBold centered medium>{t('project2.title')}</SubtitleComponent></h3>
                        <ImageSwiper images={[a1, a4, a5, a8]} thumbs={true} />
                        <ButtonComponent green onClick={() => navigate("/" + i18n.language + t('navbar.routes.project2') + "/")}>{t('misc.learnMore')}</ButtonComponent>
                    </div>

                    <div className="twoItemsContainer">
                        <div className="referencePreview" onClick={() => {
                            setReferenceContent(
                                <div className="swiperContainer">
                                    <h3><SubtitleComponent medium white centered large>{t('references.mirtex.title')}</SubtitleComponent></h3>
                                    <ImageSwiper images={[m1, m3, m4]} thumbs={true} />

                                    <div className="projectDescription">
                                        <div>{generateParagraphs(t('references.mirtex.subtitle1'))}</div>
                                    </div>
                                </div>); setOpenModal(true)
                        }} >
                            <h3><SubtitleComponent white semiBold centered medium>{t('references.mirtex.title')}</SubtitleComponent></h3>
                            <img src={m1} />
                        </div>
                        <div className="referencePreview" onClick={() => {
                            setReferenceContent(
                                <div className="swiperContainer">
                                    <h3><SubtitleComponent medium white centered large>{t('references.matiki.title')}</SubtitleComponent></h3>

                                    <ImageSwiper images={[cp2matiki, cp1matiki]} thumbs={true} />
                                    <div className="projectDescription">
                                        <div>{generateParagraphs(t('references.matiki.subtitle1'))}</div>
                                    </div>
                                </div>); setOpenModal(true)
                        }} >
                            <h3><SubtitleComponent white semiBold centered medium>{t('references.matiki.title')}</SubtitleComponent></h3>
                            <img src={cp2matiki} />
                        </div>
                    </div>
                    <div className="twoItemsContainer">
                        <div className="referencePreview" onClick={() => {
                            setReferenceContent(
                                <div className="swiperContainer">
                                    <h3><SubtitleComponent medium white centered large>{t('references.pucic.title')}</SubtitleComponent></h3>
                                    <Swiper navigation={true} modules={[Navigation, Pagination]} pagination={{ type: "progressbar" }} className="mySwiper">
                                        <SwiperSlide><img src={pucic1} /></SwiperSlide>
                                    </Swiper>
                                    <Swiper
                                        onSwiper={setThumbsSwiper}
                                        spaceBetween={10}
                                        slidesPerView={4}
                                        freeMode={true}
                                        watchSlidesProgress={true}
                                        modules={[FreeMode, Navigation, Thumbs]}
                                        className="mySwiper"
                                    >
                                        <SwiperSlide><img src={pucic1} /></SwiperSlide>

                                    </Swiper>
                                    <ImageSwiper images={[pucic1]} thumbs={true} />

                                    <div className="projectDescription">
                                        <div>{generateParagraphs(t('references.pucic.subtitle1'))}</div>
                                    </div>
                                </div>); setOpenModal(true)
                        }} >
                            <h3><SubtitleComponent white semiBold centered medium>{t('references.pucic.title')}</SubtitleComponent></h3>
                            <img src={pucic1} />
                        </div>
                        <div className="referencePreview" onClick={() => {
                            setReferenceContent(
                                <div className="swiperContainer">
                                    <h3><SubtitleComponent medium white centered large>{t('references.kliman.title')}</SubtitleComponent></h3>

                                    <ImageSwiper images={[krov1kliman, krov2kliman]} thumbs={true} />

                                    <div className="projectDescription">
                                        <div>{generateParagraphs(t('references.kliman.subtitle1'))}</div>

                                    </div>
                                </div>); setOpenModal(true)
                        }} >
                            <h3><SubtitleComponent white semiBold centered medium>{t('references.kliman.title')}</SubtitleComponent></h3>
                            <img src={krov1kliman} />
                        </div>
                    </div>
                    <div className="twoItemsContainer">
                        <div className="referencePreview" onClick={() => {
                            setReferenceContent(
                                <div className="swiperContainer">
                                    <h3><SubtitleComponent medium white centered large>{t('references.hek.title')}</SubtitleComponent></h3>

                                    <ImageSwiper images={[hek4, hek3, hek1, hek2]} thumbs={true} />

                                    <div className="projectDescription">
                                        <div>{generateParagraphs(t('references.hek.subtitle1'))}</div>
                                        {/* <SubtitleComponent max>{t('references.hek.subtitle2')}</SubtitleComponent> */}
                                    </div>
                                </div>); setOpenModal(true)
                        }} >
                            <h3><SubtitleComponent white semiBold centered medium>{t('references.hek.title')}</SubtitleComponent></h3>
                            <img src={hek4} />
                        </div>
                        <div className="referencePreview" onClick={() => {
                            setReferenceContent(
                                <div className="swiperContainer">
                                    <h3><SubtitleComponent medium white centered large>{t('references.krizanac.title')}</SubtitleComponent></h3>

                                    <ImageSwiper images={[krizanac1]} thumbs={true} />

                                    <div className="projectDescription">
                                        <div>{generateParagraphs(t('references.krizanac.subtitle1'))}</div>
                                    </div>
                                </div>); setOpenModal(true)
                        }} >
                            <h3><SubtitleComponent white semiBold centered medium>{t('references.krizanac.title')}</SubtitleComponent></h3>
                            <img src={krizanac1} />
                        </div>
                    </div>

                    <div className="twoItemsContainer">
                        <div className="referencePreview" onClick={() => {
                            setReferenceContent(
                                <div className="swiperContainer">
                                    <h3><SubtitleComponent medium white centered large>{t('references.krnica.title')}</SubtitleComponent></h3>

                                    <ImageSwiper images={[krnica1, krnica2]} thumbs={true} />

                                    <div className="projectDescription">
                                        <div>{generateParagraphs(t('references.krnica.subtitle1'))}</div>
                                    </div>
                                </div>); setOpenModal(true)
                        }} >
                            <h3><SubtitleComponent white semiBold centered medium>{t('references.krnica.title')}</SubtitleComponent></h3>
                            <img src={krnica1} />
                        </div>
                        <div className="referencePreview" onClick={() => {
                            setReferenceContent(
                                <div className="swiperContainer">
                                    <h3><SubtitleComponent medium white centered large>{t('references.krculi1.title')}</SubtitleComponent></h3>

                                    <ImageSwiper images={[krculi1_1]} thumbs={true} />

                                    <div className="projectDescription">
                                        <div>{generateParagraphs(t('references.krculi1.subtitle1'))}</div>
                                    </div>
                                </div>); setOpenModal(true)
                        }} >
                            <h3><SubtitleComponent white semiBold centered medium>{t('references.krculi1.title')}</SubtitleComponent></h3>
                            <img src={krculi1_1} />
                        </div>
                    </div>
                    <div className="twoItemsContainer">
                        <div className="referencePreview" onClick={() => {
                            setReferenceContent(
                                <div className="swiperContainer">
                                    <h3><SubtitleComponent medium white centered large>{t('references.vrsar1.title')}</SubtitleComponent></h3>

                                    <ImageSwiper images={[vrsar11, vrsar12]} thumbs={true} />

                                    <div className="projectDescription">
                                        <div>{generateParagraphs(t('references.vrsar1.subtitle1'))}</div>
                                    </div>
                                </div>); setOpenModal(true)
                        }} >
                            <h3><SubtitleComponent white semiBold centered medium>{t('references.vrsar1.title')}</SubtitleComponent></h3>
                            <img src={vrsar11} />
                        </div>
                        <div className="referencePreview" onClick={() => {
                            setReferenceContent(
                                <div className="swiperContainer">
                                    <h3><SubtitleComponent medium white centered large>{t('references.peric.title')}</SubtitleComponent></h3>

                                    <ImageSwiper images={[peric2, peric1]} thumbs={true} />

                                    <div className="projectDescription">
                                        <div>{generateParagraphs(t('references.peric.subtitle1'))}</div>
                                    </div>
                                </div>); setOpenModal(true)
                        }} >
                            <h3><SubtitleComponent white semiBold centered medium>{t('references.peric.title')}</SubtitleComponent></h3>
                            <img src={peric2} />
                        </div>
                    </div>
                    <div className="twoItemsContainer">
                        <div className="referencePreview" onClick={() => {
                            setReferenceContent(
                                <div className="swiperContainer">
                                    <h3><SubtitleComponent medium white centered large>{t('references.buzet.title')}</SubtitleComponent></h3>
                                    <ImageSwiper images={[buzet1, buzet2, buzet3, buzet4]} thumbs={true} />

                                    <div className="projectDescription">
                                        <div>{generateParagraphs(t('references.buzet.subtitle1'))}</div>
                                    </div>
                                </div>); setOpenModal(true)
                        }} >
                            <h3><SubtitleComponent white semiBold centered medium>{t('references.buzet.title')}</SubtitleComponent></h3>
                            <img src={buzet1} />
                        </div>
                        <div className="referencePreview" onClick={() => {
                            setReferenceContent(
                                <div className="swiperContainer">
                                    <h3><SubtitleComponent medium white centered large>{t('references.jelenic.title')}</SubtitleComponent></h3>
                                    <div className="projectDescription">
                                        <div>{generateParagraphs(t('references.jelenic.subtitle1'))}</div>
                                    </div>
                                </div>); setOpenModal(true)
                        }} >
                            <h3><SubtitleComponent white semiBold centered medium>{t('references.jelenic.title')}</SubtitleComponent></h3>
                            <img src={jelenic3} />
                        </div>
                    </div>
                    <div className="twoItemsContainer">
                        <div className="referencePreview" onClick={() => {
                            setReferenceContent(
                                <div className="swiperContainer">
                                    <h3><SubtitleComponent medium white centered large>{t('references.krculi2.title')}</SubtitleComponent></h3>
                                    <ImageSwiper images={[krculi2_2]} thumbs={true} />

                                    <div className="projectDescription">
                                        <div>{generateParagraphs(t('references.krculi2.subtitle1'))}</div>
                                    </div>
                                </div>); setOpenModal(true)
                        }} >
                            <h3><SubtitleComponent white semiBold centered medium>{t('references.krculi2.title')}</SubtitleComponent></h3>
                            <img src={krculi2_2} />
                        </div>
                        <div className="referencePreview" onClick={() => {
                            setReferenceContent(
                                <div className="swiperContainer">
                                    <h3><SubtitleComponent medium white centered large>{t('references.vrsar2.title')}</SubtitleComponent></h3>

                                    <ImageSwiper images={[vrsar21, vrsar22]} thumbs={true} />

                                    <div className="projectDescription">
                                        <div>{generateParagraphs(t('references.vrsar2.subtitle1'))}</div>
                                    </div>
                                </div>); setOpenModal(true)
                        }} >
                            <h3><SubtitleComponent white semiBold centered medium>{t('references.vrsar2.title')}</SubtitleComponent></h3>
                            <img src={vrsar21} />
                        </div>
                    </div>
                    <div className="twoItemsContainer">
                        <div className="referencePreview" onClick={() => {
                            setReferenceContent(
                                <div className="swiperContainer">
                                    <h3><SubtitleComponent medium white centered large>{t('references.buletic1.title')}</SubtitleComponent></h3>
                                    <ImageSwiper images={[buletic_1]} thumbs={true} />

                                    <div className="projectDescription">
                                        <div>{generateParagraphs(t('references.buletic1.subtitle1'))}</div>
                                    </div>
                                </div>); setOpenModal(true)
                        }} >
                            <h3><SubtitleComponent white semiBold centered medium>{t('references.buletic1.title')}</SubtitleComponent></h3>
                            <img src={buletic_1} />
                        </div>

                    </div>




                </Trans>
            </ContainerComponent>
        </div>
    )
}

export default withTranslation()(References);
