import i18next from 'i18next';

export interface CategoryEquipment {
  solar_panels: string,
  inverters: string,
  batteries: string,
  // carport: string,
  'inverter-accessories'?: string,
  inverter_accessories: string,
  battery_accessories: string,
  cables_and_connectors: string,
  automatization: string,
  charger_accessories: string,
  chargers: string,
  all: string
}


const Categories: CategoryEquipment = {
  solar_panels: i18next.t('solarEquipment.categories.solar_panels'),
  inverters: i18next.t('solarEquipment.categories.inverters'),
  batteries: i18next.t('solarEquipment.categories.batteries'),
  // carport: i18next.t('solarEquipment.categories.carport'),
  // 'inverter-accessories': i18next.t('solarEquipment.categories.inverter_accessories'),
  inverter_accessories: i18next.t('solarEquipment.categories.inverter_accessories'),
  battery_accessories: i18next.t('solarEquipment.categories.battery_accessories'),
  cables_and_connectors: i18next.t('solarEquipment.categories.cables_and_connectors'),
  automatization: i18next.t('solarEquipment.categories.automatization'),
  charger_accessories: i18next.t('solarEquipment.categories.charger_accessories'),
  chargers: i18next.t('solarEquipment.categories.chargers'),
  all: i18next.t('misc.all')
};

export const CategoriesEN: CategoryEquipment = {
  solar_panels: i18next.t('solarEquipment.categories.solar_panels'),
  inverters: i18next.t('solarEquipment.categories.inverters'),
  batteries: i18next.t('solarEquipment.categories.batteries'),
  // carport: i18next.t('solarEquipment.categories.carport'),
  'inverter-accessories': i18next.t('solarEquipment.categories.inverter_accessories'),
  inverter_accessories: i18next.t('solarEquipment.categories.inverter_accessories'),
  battery_accessories: i18next.t('solarEquipment.categories.battery_accessories'),
  cables_and_connectors: i18next.t('solarEquipment.categories.cables_and_connectors'),
  automatization: i18next.t('solarEquipment.categories.automatization'),
  charger_accessories: i18next.t('solarEquipment.categories.charger_accessories'),
  chargers: i18next.t('solarEquipment.categories.chargers'),
  all: i18next.t('misc.all')
};

export const UrlCategories: CategoryEquipment = {
  solar_panels: i18next.t('solarEquipment.urlCategories.solar_panels'),
  inverters: i18next.t('solarEquipment.urlCategories.inverters'),
  batteries: i18next.t('solarEquipment.urlCategories.batteries'),
  // carport: i18next.t('solarEquipment.urlCategories.carport'),
  'inverter-accessories': i18next.t('solarEquipment.urlCategories.inverter_accessories'),
  inverter_accessories: i18next.t('solarEquipment.urlCategories.inverter_accessories'),
  battery_accessories: i18next.t('solarEquipment.categories.battery_accessories'),
  cables_and_connectors: i18next.t('solarEquipment.urlCategories.cables_and_connectors'),
  automatization: i18next.t('solarEquipment.urlCategories.automatization'),
  chargers: i18next.t('solarEquipment.urlCategories.chargers'),
  charger_accessories: i18next.t('solarEquipment.urlCategories.charger_accessories'),
  all: i18next.t('solarEquipment.urlCategories.all')
};
export const UrlCategoriesEN: CategoryEquipment = {
  solar_panels: i18next.t('solarEquipment.urlCategories.solar_panels'),
  inverters: i18next.t('solarEquipment.urlCategories.inverters'),
  batteries: i18next.t('solarEquipment.urlCategories.batteries'),
  // carport: i18next.t('solarEquipment.urlCategories.carport'),
  'inverter-accessories': i18next.t('solarEquipment.urlCategories.inverter_accessories'),
  inverter_accessories: i18next.t('solarEquipment.urlCategories.inverter_accessories'),
  battery_accessories: i18next.t('solarEquipment.categories.battery_accessories'),
  cables_and_connectors: i18next.t('solarEquipment.urlCategories.cables_and_connectors'),
  automatization: i18next.t('solarEquipment.urlCategories.automatization'),
  chargers: i18next.t('solarEquipment.urlCategories.chargers'),
  charger_accessories: i18next.t('solarEquipment.urlCategories.charger_accessories'),
  all: i18next.t('solarEquipment.urlCategories.all')
};

export function convertChargerCategoryLink(categoryUrl:string):string{
  if(categoryUrl == 'chargers' || categoryUrl == 'charger_accessories'){
      return i18next.t('navbar.routes.chargers')
  }
  else return i18next.t('navbar.routes.equipment')
}



export default Categories;
